import * as React from 'react'
import Layout from '../components/Layout'

const FourOhFour = () => (
  <Layout>
    <h1>FourOhFour</h1>
    <p>Sorry, we couldn't find the page you were looking for.</p>
  </Layout>
)

export default FourOhFour
